<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__superviseinspect">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.inspector" placeholder="巡视人" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="inspectDaterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            :range-separator="$t('datePicker.range')"
            :start-placeholder="$t('datePicker.start')"
            :end-placeholder="$t('datePicker.end')">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:superviseinspect:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:superviseinspect:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="inspectDate" label="巡视日期" width="100" align="center" sortable="custom"  show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="viewHandle(scope.row.id)">{{scope.row.inspectDate}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="inspector" label="巡视人" width="140" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="scope" label="巡视范围" min-width="150" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="250">
          <template v-slot="{row}">
            <table-button role="print" v-if="$hasPermission('opm:superviseinspect:print')" @click="printHandle(row.id)"></table-button>
            <table-button role="update" v-if="$hasPermission('opm:superviseinspect:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:superviseinspect:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:superviseinspect:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './superviseinspect-add-or-update'
import ViewPage from './superviseinspect-view'
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/superviseInspect/page',
        getDataListIsPage: true,
        exportURL: '/opm/superviseInspect/export',
        deleteURL: '/opm/superviseInspect',
      },
      inspectDaterange: null,
      dataForm: {
        prjId:'',
        contractId:'',
        inspectStartDate: '',
        inspectEndDate: '',
        inspector:''
      },
    }
  },
  watch: {
    inspectDaterange (val) {
      if (val == null) {
        this.dataForm.inspectStartDate = null
        this.dataForm.inspectEndDate = null
      } else {
        this.dataForm.inspectStartDate = val[0]
        this.dataForm.inspectEndDate = val[1]
      }
    },
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },

    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
    // 打印巡视记录单
    printHandle(id) {
      var params = qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        'id': id,
        'prjId': this.dataForm.prjId
      })
      window.open(`${window.SITE_CONFIG['apiURL']}/opm/superviseInspect/print?${params}`)
    },
  }
}
</script>