<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__sheettemplate">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.title" placeholder="名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:vehicleWaypoint:delete')" @click="deleteHandle()"></toolbar-button>
            <toolbar-button icon="el-icon-edit-outline" name="绘制路线点" v-if="$hasPermission('opm:vehicleWaypoint:update')" @click="drawHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="title" label="名称" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="150">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:vehicleWaypoint:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:vehicleWaypoint:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './vehiclewaypoint-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/vehicleWaypoint/page',
        getDataListIsPage: true,
        deleteURL: '/opm/vehicleWaypoint',
      },
      dataForm: {
        prjId: '',
        title: '',
      },
    }
  },
  components: {
    AddOrUpdate
  },
  methods: {
    prjChangeHandle(data) {
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      if (this.dataForm.prjId) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
          this.$refs.addOrUpdate.init()
        })
      } else {
        this.$message({
          message: '请先选择一个项目！',
          type: 'warning',
          duration: 3000
        })
      }
    },
    drawHandle () {
      const routeUrl = this.$router.resolve({
        path: "/dts-draw-vehicle-waypoint",
      })
      window.open(routeUrl.href, "_blank")
    }
  }
}
</script>