<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-tenant__tenant">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.tenantName" :placeholder="$t('tenant.tenantName')" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        highlight-current-row
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="username" :label="$t('tenant.username')" sortable="custom" header-align="center" align="center"></el-table-column>
        <el-table-column prop="tenantCode" :label="$t('tenant.tenantCode')" header-align="center" align="center"></el-table-column>
        <el-table-column prop="tenantName" :label="$t('tenant.tenantName')" header-align="center" align="center"></el-table-column>
        <el-table-column prop="email" :label="$t('tenant.email')" header-align="center" align="center"></el-table-column>
        <el-table-column prop="mobile" :label="$t('tenant.mobile')" sortable="custom" header-align="center" align="center"></el-table-column>
        <el-table-column prop="status" :label="$t('tenant.status')" sortable="custom" header-align="center" align="center" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="danger">{{ $t('tenant.status0') }}</el-tag>
            <el-tag v-else size="small" type="success">{{ $t('tenant.status1') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="数据量统计" header-align="center" align="center" width="90">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewDataStatistics(row.tenantCode)">查看</el-button>
            <el-button type="text" size="small" @click="configDataStatistics(row)">配置</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" :label="$t('tenant.createDate')" sortable="custom" header-align="center" align="center" width="180"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="230">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openInitBaseDataModal(scope.row.tenantCode)">初始化</el-button>
            <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">{{ $t('update') }}</el-button>
            <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <data-statistics v-if="dataStatisticsVisible" ref="dataStatistics" @close="closeCustomDialogHandle"></data-statistics>
      <vxe-modal v-model="initBaseDataModal" size="small" type="confirm" title="初始化基础数据" show-footer>
        <template #default>
          <el-row v-for="(item) in initBaseDataConfig" :key="item.name">
            <el-col :span="2">
              <el-checkbox v-if="!initBaseDataProcessing" v-model="item.checked"></el-checkbox>
              <i v-else-if="item.status == 0" class="vxe-icon-checkbox-unchecked"></i>
              <i v-else-if="item.status == 1" class="vxe-icon-indicator roll icon-waiting"></i>
              <i v-else-if="item.status == 2" class="vxe-icon-check icon-success"></i>
              <i v-else-if="item.status == 3" class="vxe-icon-close icon-fail"></i>
            </el-col>
            <el-col :span="10">
              <span>{{ item.name }}</span>
            </el-col>
            <el-col :span="12">
              <span style="color: red">{{ item.errMsg }}</span>
            </el-col>
          </el-row>
        </template>
        <template #footer>
          <vxe-button content="关闭" @click="closeInitBaseDataModal"></vxe-button>
          <vxe-button v-if="!initBaseDataProcessing" content="确定" status="primary" @click="doInitBaseData"></vxe-button>
        </template>
      </vxe-modal>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './tenant-add-or-update'
import DataStatistics from './tenant-data-statistics'
import qs from 'qs'
import {addDynamicRoute} from "@/router";
import Template from "@/views/modules/devtools/template";

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/tenant/page',
        getDataListIsPage: true,
        deleteURL: '/sys/tenant',
        exportURL: '/sys/tenant/export'
      },
      dataForm: {
        tenantName: ''
      },
      dataStatisticsVisible: false,
      initBaseDataModal: false,
      initBaseDataProcessing: false,
      chosenTenantCode: '',
      initBaseDataConfig: []
    }
  },
  components: {
    Template,
    AddOrUpdate,
    DataStatistics,
  },
  methods: {
    viewDataStatistics (tenantCode) {
      this.dataStatisticsVisible = true
      this.$nextTick(() => {
        this.$refs.dataStatistics.dataForm.tenantCode = tenantCode
        this.$refs.dataStatistics.init()
      })
    },
    configDataStatistics (row) {
      let routeParams = {
        routeName: `data-statistics_${row.tenantCode}`,
        menuId: `${this.$route.meta.menuId}`,
        title: `数据量统计配置 - ${row.tenantName}`,
        path: 'tenant/datastatistics',
        params: {
          tenantCode: row.tenantCode
        }
      }
      addDynamicRoute(routeParams, this.$router)
    },
    closeCustomDialogHandle () {
      this.dataStatisticsVisible = false
    },
    openInitBaseDataModal(tenantCode) {
      this.chosenTenantCode = tenantCode
      this.resetInitBaseDataModal()
      this.initBaseDataModal = true
    },
    closeInitBaseDataModal() {
      this.initBaseDataModal = false
    },
    resetInitBaseDataModal() {
      this.initBaseDataProcessing = false
      this.initBaseDataConfig = [
        // status: 0-不初始化, 1-初始化中, 2-初始化完成, 3-初始化失败
        {
          name: '数据字典',
          status: 0,
          checked: true,
          initFunction: this.initDictData,
          errMsg: ''
        },
        {
          name: '数据量统计配置',
          status: 0,
          checked: true,
          initFunction: this.initDataStatistics,
          errMsg: ''
        },
        {
          name: '清单范本',
          status: 0,
          checked: true,
          initFunction: this.initBoqTemplate,
          errMsg: ''
        },
        {
          name: '分部分项模板',
          status: 0,
          checked: true,
          initFunction: this.initWbsTemplate,
          errMsg: ''
        },
      ]
    },
    doInitBaseData() {
      this.initBaseDataProcessing = true
      for (let item of this.initBaseDataConfig) {
        if (item.checked) {
          // 状态改为初始化中
          item.status = 1
          item.initFunction(this.chosenTenantCode).then(() => {
            // 成功
            item.status = 2
          }).catch((errMsg) => {
            // 失败
            item.status = 3
            item.errMsg = errMsg
          })
        } else {
          // 状态改为不初始化
          item.status = 0
        }
      }
    },
    initDictData(tenantCode) {
      return new Promise((resolve, reject) => {
        this.$http['post'](`/sys/dict/type/syncDictData?tenantCode=${tenantCode}`).then(({ data: res }) => {
          if (res.code !== 0) {
            reject(res.msg)
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500
          })
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    initDataStatistics(tenantCode) {
      return new Promise((resolve, reject) => {
        this.$http['post'](`/opm/dataStatistics/init?tenantCode=${tenantCode}`).then(({ data: res }) => {
          if (res.code !== 0) {
            reject(res.msg)
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500
          })
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    initBoqTemplate(tenantCode) {
      return new Promise((resolve, reject) => {
        this.$http['post'](`/mps/boqTemplateMain/init?tenantCode=${tenantCode}`).then(({ data: res }) => {
          if (res.code !== 0) {
            reject(res.msg)
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500
          })
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    initWbsTemplate(tenantCode) {
      return new Promise((resolve, reject) => {
        this.$http['post'](`/mps/wbsTemplate/init?tenantCode=${tenantCode}`).then(({ data: res }) => {
          if (res.code !== 0) {
            reject(res.msg)
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500
          })
          resolve()
        }).catch(() => {
          reject()
        })
      })
    }
  }
}
</script>
<style scoped>
.icon-waiting {
  color: orange;
}
.icon-success {
  color: green;
}
.icon-fail {
  color: red;
}
</style>